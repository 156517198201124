<template>
  <b-row>
    <b-col cols="3">
      <TreeView :setDong="setDong" :selected="dongSearch == '' ? '전체' : dongSearch"></TreeView>
    </b-col>
    <b-col cols="9">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">주민투표 목록</h5>
        </b-card-body>

        <b-table
          responsive
          class="mb-0 p-3"
          head-variant="light"
          :items="items"
          :current-page="currentPage"
          :per-page="perPage"
          :fields="fields"
        >

        <template #cell(status)="data">
          <b-badge
            v-if="(data.item.status == 0)"
            pill
            :class="`px-2 text-white badge bg-success`"
          >
            진행중
          </b-badge>
          <b-badge
            v-if="(data.item.status != 0)"
            pill
            :class="`px-2 text-white badge bg-danger`"
          >
            완료
          </b-badge>
        </template>

        </b-table>

        <div class="row p-3 m-0 d-flex justify-content-between">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            class="my-0"
          ></b-pagination>
          <b-button variant="outline-info" @click="$router.push('/voteWrite')">글쓰기</b-button>
        </div>
      </b-card>
      
    </b-col>

  </b-row>
  
</template>

<style>
.b-table {
  border: 1px solid #eee
}
</style>

<script>
import TreeView from '../components/apps/TreeView';
export default {
  name: "Vote",

  data: () => ({
    page: {
      title: "PaginationTable",
    },
    fields: [
      {
        key: "target",
        label: "해당 동",
      },
      {
        key: "title",
        label: "제목",
      },
      {
        key: "date",
        label: "작성일",
      },
      {
        key: "vote_end",
        label: "투표기한",
      },
      {
        key: "status",
        label: "상태",
      }
    ],
    items: [
      {idx: "1", target: "전체", title: "피트니스 센터 G.X프로그램 선호도 조사", date: "22.08.21", vote_end: "~ 22.10.31", status: 0},
      {idx: "2", target: "전체", title: "경비원 감축 관련 투표", date: "22.10.22", vote_end: "~ 22.10.29", status: 0},
      {idx: "3", target: "전체", title: "청소년들이 원하는 공유공간 투표", date: "21.10.14", vote_end: "~ 21.10.15", status: 1},
      {idx: "4", target: "전체", title: "단지내 유치원 주변차량 통제 설문", date: "22.08.03", vote_end: "~ 22.08.12", status: 1},
      {idx: "5", target: "108", title: "108동 후보자 투표", date: "22.05.12", vote_end: "~ 22.06.12", status: 1},
      {idx: "6", target: "107", title: "107동 후보자 투표", date: "22.05.12", vote_end: "~ 22.06.12", status: 1},
      {idx: "7", target: "106", title: "106동 후보자 투표", date: "22.05.12", vote_end: "~ 22.06.12", status: 1},
      {idx: "8", target: "105", title: "105동 후보자 투표", date: "22.05.12", vote_end: "~ 22.06.12", status: 1},
      {idx: "9", target: "104", title: "104동 후보자 투표", date: "22.05.12", vote_end: "~ 22.06.12", status: 1},
      {idx: "10", target: "103", title: "103동 후보자 투표", date: "22.05.12", vote_end: "~ 22.06.12", status: 1},
      {idx: "11", target: "102", title: "102동 후보자 투표", date: "22.05.12", vote_end: "~ 22.06.12", status: 1},
      {idx: "12", target: "101", title: "101동 후보자 투표", date: "22.05.12", vote_end: "~ 22.06.12", status: 1} 
    ],
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    dongSearch: "",

  }),
  components: {TreeView},
  methods: {
    setDong(dong, isChild){
      if(isChild){
        this.dongSearch = dong;
      }
      else{
        this.dongSearch = "";
      }
    }
  },
  mounted() {
    this.totalRows = this.items.length;
  },
};
</script>
